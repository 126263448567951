$usa-btn-small-width:   4.5rem;
$usa-btn-medium-width:  8.5rem;
$usa-btn-big-width:     11.6rem;

@mixin search-icon {
  background-image: url('#{$image-path}/search.png');
  background-image: url('#{$image-path}/search.svg');
  background-position: 50%;
  background-repeat: no-repeat;
}

.usa-search {
  @include clearfix;
  max-width: none;
  position: relative;

  [type=search],
  .usa-search-input {
    @include padding(0 null);
    -webkit-appearance: none;
    border-bottom-right-radius: 0;
    border-right: none;
    border-top-right-radius: 0;
    box-sizing: border-box;
    float: left;
    font-size: $small-font-size;
    height: 3.3rem;
    margin: 0;
    width: calc(100% - #{$usa-btn-small-width});

    @include media($small-screen) {
      width: calc(100% - #{$usa-btn-medium-width});
    }
  }

  [type=submit],
  .usa-search-submit {
    @include search-icon;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    height: 3.3rem;
    margin: 0;
    padding: 0;
    width: $usa-btn-small-width;

    @include media($small-screen) {
      background-image: none;
      width: $usa-btn-medium-width;
    }
  }

  .usa-search-submit-text {
    display: none;

    @include media($small-screen) {
      display: block;
    }
  }

  &.usa-search-big {
    @include media($small-screen) {
      $height: 4.4rem;
      $width: $usa-btn-big-width;

      [type=search],
      .usa-search-input {
        font-size: $base-font-size;
        height: $height;
        width: calc(100% - #{$width});
      }

      [type=submit],
      .usa-search-submit {
        font-size: $h3-font-size;
        height: $height;
        width: $width;
      }
    }
  }

  &.usa-search-small {
    @include media($small-screen) {
      $width: $usa-btn-small-width;

      [type=search],
      .usa-search-input {
        width: calc(100% - #{$width});
      }

      [type=submit],
      .usa-search-submit {
        @include search-icon;
        width: $width;
      }
    }

    $lt-nav-width: $nav-width - 1px;

    @media screen and (max-width: $lt-nav-width) {
      &.usa-sr-only {
        left: auto;
        position: relative;
      }
    }
  }
}
