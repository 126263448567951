ul,
ol {
  @include margin(1em null);
  padding-left: 1.94em; // Approximately 15px left padding at default font size
}

li {
  line-height: $base-line-height;
  margin-bottom: 0.5em;

  &:last-child {
    margin-bottom: 0;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  + ul,
  + ol {
    margin-top: 0;
  }
}

// Unstyled lists
.usa-unstyled-list {
  @include unstyled-list;
}
