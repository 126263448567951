.usa-section {
  @include padding($site-margins null);

  @include media($medium-screen) {
    @include padding(6rem null);
  }
}

.usa-section-light {
  background-color: $color-gray-lightest;
}

.usa-section-dark {
  background-color: $color-primary-darkest;
  color: $color-white;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $color-primary-alt;
  }

  p {
    color: $color-white;
  }

  a {
    color: $color-gray-lighter;

    &:hover {
      color: $color-white;
    }
  }
}
