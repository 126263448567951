/*! uswds @version */

// Vendor -------------- //
@import 'lib/bourbon';
@import 'lib/neat';
@import 'lib/normalize';

// Core -------------- //
@import 'core/variables';
@import 'core/fonts';
@import 'core/base';
@import 'core/grid';
@import 'core/utilities';

// Elements -------------- //
// Styles basic HTML elements
@import 'elements/buttons';
@import 'elements/figure';
@import 'elements/inputs';
@import 'elements/labels';
@import 'elements/list';
@import 'elements/table';
@import 'elements/typography';

// Components -------------- //
@import 'components/accordions';
@import 'components/alerts';
@import 'components/banner';
@import 'components/footer';
@import 'components/forms';
@import 'components/graphic-list';
@import 'components/header';
@import 'components/hero';
@import 'components/layout';
@import 'components/media-block';
@import 'components/navigation';
@import 'components/search';
@import 'components/section';
@import 'components/sidenav';
@import 'components/skipnav';
