// Variables

$accordion-border: 3px solid $color-gray-lightest;

// Accordion Styles

@mixin accordion-list-styles {
  @include unstyled-list;
  color: $color-base;
  margin: 0;
  padding: 0;
  width: 100%;
}

@mixin accordion-list-item-styles {
  background-color: $color-gray-lightest;
  font-family: $font-sans;
  list-style: none;
  margin-bottom: 6px;
  width: 100%;
}

// scss-lint:disable PropertyCount
@mixin accordion-button-styles {
  @include button-unstyled;
  background-color: $color-gray-lightest;
  background-image: url('#{$image-path}/minus.png');
  background-image: url('#{$image-path}/minus.svg');
  background-position: right 3rem center;
  background-repeat: no-repeat;
  background-size: 13px;
  color: $color-base;
  cursor: pointer;
  display: inline-block;
  font-family: $font-sans;
  font-weight: $font-bold;
  margin: 0;
  padding: 1.5rem 5.5rem 1.5rem 3rem;
  width: 100%;

  &:focus {
    box-shadow: $focus-shadow;
  }

  &:hover {
    background-color: $color-gray-lighter;
    color: $color-base;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }
}
// scss-lint:enable PropertyCount

@mixin accordion-button-unopened-styles {
  background-image: url('#{$image-path}/plus.png');
  background-image: url('#{$image-path}/plus.svg');
  background-repeat: no-repeat;
  background-size: 1.3rem;
}

.usa-accordion,
.usa-accordion-bordered {
  @include accordion-list-styles;

  + .usa-accordion,
  + .usa-accordion-bordered {
    margin-top: 1rem;
  }

  // TODO deprecated ruleset based on old accordion.
  > ul {
    @include accordion-list-styles;

    // TODO deprecated ruleset based on old accordion.
    > li {
      @include accordion-list-item-styles;
    }

    // TODO deprecated ruleset based on old accordion.
    button {
      @include accordion-button-styles;
    }

    // TODO deprecated ruleset based on old accordion.
    [aria-expanded=false] {
      @include accordion-button-unopened-styles;
    }
  }

  > li {
    @include accordion-list-item-styles;
  }

}

.usa-accordion-bordered {
  .usa-accordion-content {
    border-bottom: $accordion-border;
    border-left: $accordion-border;
    border-right: $accordion-border;
  }
}

.usa-accordion-content {
  background-color: $color-white;
  overflow: auto;
  padding: 3rem;

  > *:first-child {
    margin-top: 0;
  }

  > *:last-child {
    margin-bottom: 0;
  }

  &:not([aria-hidden]) {
    @include sr-only();
  }

  @include accessibly-hidden();
}

.usa-accordion-button {
  @include accordion-button-styles;
}

.usa-accordion-button[aria-expanded=false] {
  @include accordion-button-unopened-styles;
}