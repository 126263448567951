// A collection of media block objects

.usa-graphic_list {
  .usa-graphic_list-row {
    .usa-media_block {
      margin-bottom: $site-margins;

      @include media($medium-screen) {
        margin-bottom: $site-margins * 2;
      }
    }

    &:last-child {
      .usa-media_block {
        @include media($medium-screen) {
          margin-bottom: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .usa-media_block-img {
    margin-right: $site-margins;
  }

  .usa-media_block-body {
    > :first-child {
      margin-top: 0;
    }
  }
}
