img {
  max-width: 100%;
}

// TODO: Add documentation instructions for using this class on image links
@mixin media-link {
  display: inline-block;
  line-height: 0;
}

.media_link {
  @include media-link();
}
