// These variables drive almost every aspect of the U.S. Web Design
// system. Every variable is declared as so that it can be
// overridden by a previous import.
//
// XXX Do not modify this file!
//
// If you want to override a variable, set that variable in one of your
// own partials, then @import '{path/to/uswds/}core/variables'.

// Typography
// Removing the !default from $em-base so we are not inheriting that 
// value from Bourbon.
// $em-base:             10px;
// $small-font-size:     rem(14px);
// $lead-font-size:      rem(20px);
// $title-font-size:     rem(52px);
// $h1-font-size:        rem(40px);
// $h2-font-size:        rem(30px);
// $h3-font-size:        rem(20px);
// $h4-font-size:        rem(17px);
// $h5-font-size:        rem(15px);
// $h6-font-size:        rem(13px);
// $base-line-height:    1.5;
// $heading-line-height: 1.3;
// $lead-line-height:    1.7;

$font-sans:           'Noto Sans Japanese', "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro W3", Meiryo, "メイリオ", 'Source Sans Pro', sans-serif;
$font-serif:          'Noto Sans Japanese', "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro W3", Meiryo, "メイリオ", 'Merriweather', serif;

// $font-normal:         400;
// $font-bold:           700;

// Color
// $color-primary:              #0071bc;
// $color-primary-darker:       #205493;
// $color-primary-darkest:      #112e51;

// $color-primary-alt:          #02bfe7;
// $color-primary-alt-dark:     #00a6d2;
// $color-primary-alt-darkest:  #046b99;
// $color-primary-alt-light:    #9bdaf1; // lighten($color-primary-alt, 60%)
// $color-primary-alt-lightest: #e1f3f8; // lighten($color-primary-alt, 90%)

// $color-secondary:            #e31c3d;
// $color-secondary-dark:       #cd2026;
// $color-secondary-darkest:    #981b1e;
// $color-secondary-light:      #e59393; // lighten($color-secondary, 60%)
// $color-secondary-lightest:   #f9dede; // lighten($color-secondary, 90%)

// $color-white:                #ffffff;
// $color-base:                 #212121;
// $color-black:                #000000;

// $color-gray-dark:            #323a45;
// $color-gray:                 #5b616b; // lighten($color-gray-dark, 20%)
// $color-gray-medium:          #757575; // lightest gray that passes color contrast
// $color-gray-light:           #aeb0b5; // lighten($color-gray-dark, 60%)
// $color-gray-lighter:         #d6d7d9; // lighten($color-gray-dark, 80%)
// $color-gray-lightest:        #f1f1f1; // lighten($color-gray-dark, 91%)

// $color-gray-warm-dark:       #494440;
// $color-gray-warm-light:      #e4e2e0; // lighten($color-gray-warm-dark, 90%)
// $color-gray-cool-light:      #dce4ef; // lighten($color-primary, 90%)

// $color-gold:                 #fdb81e;
// $color-gold-light:           #f9c642; //  lighten($color-gold, 20%)
// $color-gold-lighter:         #fad980; //  lighten($color-gold, 60%)
// $color-gold-lightest:        #fff1d2; //  lighten($color-gold, 83%)

// $color-green:                #2e8540;
// $color-green-light:          #4aa564; // lighten($color-green, 20%)
// $color-green-lighter:        #94bfa2; // lighten($color-green, 60%)
// $color-green-lightest:       #e7f4e4; // lighten($color-green, 60%)

// $color-cool-blue:            #205493;
// $color-cool-blue-light:      #4773aa; // lighten($color-cool-blue, 20%)
// $color-cool-blue-lighter:    #8ba6ca; // lighten($color-cool-blue, 60%)
// $color-cool-blue-lightest:   #dce4ef; // lighten($color-cool-blue, 90%)

// $color-focus:                #3e94cf;
// $color-visited:              #4c2c92;

// $color-shadow:               rgba(#000, 0.3);

// Mobile First Breakpoints
// $small-screen:  481px;
// $medium-screen: 600px;
// $large-screen:  1201px;

// Grid column counts by screen size
// $grid-columns-small: 1;
// $grid-columns-medium: 6;
// $grid-columns-large: 12;

// @media single-keyword helpers
// $small: new-breakpoint(min-width $small-screen $grid-columns-small);
// $medium: new-breakpoint(min-width $medium-screen $grid-columns-medium);
// $large: new-breakpoint(min-width $large-screen $grid-columns-large);

// Relative font and image file paths
$font-path:   '../uswds-1.0.0/fonts';
$image-path:  '../uswds-1.0.0/img';

// Set $asset-pipeline to true if you're using the Rails Asset Pipeline
// $asset-pipeline:      false;

// Magic Numbers
$text-max-width:      100%;
// $lead-max-width:      200%;
// $site-max-width:      1040px;
// $site-margins:        3rem;
// $site-margins-mobile: 1.5rem;
// $article-max-width:   600px;
// $input-max-width:     46rem;
// $border-radius:       rem(3px);
// $box-shadow:          0 0 2px $color-shadow;
// $focus-shadow:        0 0 3px $color-focus, 0 0 7px $color-focus;
// $nav-width:           951px;

// 44 x 44 pixels hit target following Apple iOS Human Interface
// Guidelines
// $hit-area: 4.4rem;
