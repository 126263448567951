table {
  border-spacing: 0;
  margin: 2em 0;
  min-width: 100%;

  thead {
    th,
    td {
      background-color: $color-gray-lightest;
    }
  }

  th {
    text-align: left;
  }

  tbody {
    th {
      font-weight: $font-normal;
    }
  }

  th,
  td {
    background-color: $color-white;
    border: 1px solid $color-gray;
    padding: 1.5rem;
  }
}

.usa-table-borderless {
  thead {
    tr {
      background-color: transparent;
    }

    th {
      border-top: 0;
    }
  }

  th,
  td {
    border-left: 0;
    border-right: 0;
  }
}
