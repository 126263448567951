// Buttons variables

$button-stroke: inset 0 0 0 2px;

// Buttons

/* stylelint-disable selector-no-qualifying-type */
.usa-button,
.usa-button-primary,
.usa-button:visited,
.usa-button-primary:visited,
button,
[type=button],
[type=submit],
[type=reset],
[type=image] {
  @include font-smoothing;
  @include margin(0.5em 0.5em 0.5em null);
  appearance: none;
  background-color: $color-primary;
  border: 0;
  border-radius: $border-radius;
  color: $color-white;
  cursor: pointer;
  display: inline-block;
  font-family: $font-sans;
  font-size: $base-font-size;
  font-weight: $font-bold;
  line-height: 1;
  outline: none;
  padding: 1rem 2rem;
  text-align: center;
  text-decoration: none;
  width: 100%;

  @include media($small-screen) {
    width: auto;
  }

  &:hover,
  &.usa-button-hover {
    background-color: $color-primary-darker;
    border-bottom: 0;
    color: $color-white;
    text-decoration: none;
  }

  &:focus,
  &.usa-button-focus {
    box-shadow: $focus-shadow;
  }

  &:active,
  &.usa-button-active {
    background-color: $color-primary-darkest;
  }

  &.usa-button-primary-alt {
    background-color: $color-primary-alt;
    color: $color-base;

    &:hover,
    &.usa-button-hover {
      background-color: $color-primary-alt-dark;
    }

    &:active,
    &.usa-button-active {
      background-color: $color-primary-alt-darkest;
      color: $color-white;
    }
  }

  &.usa-button-secondary {
    background-color: $color-secondary;

    &:hover,
    &.usa-button-hover {
      background-color: $color-secondary-dark;
    }

    &:active,
    &.usa-button-active {
      background-color: $color-secondary-darkest;
    }
  }

  &.usa-button-gray {
    background-color: $color-gray;

    &:hover,
    &.usa-button-hover {
      background-color: $color-gray-dark;
    }

    &:active,
    &.usa-button-active {
      background-color: $color-base;
    }
  }

  &.usa-button-outline {
    background-color: $color-white;
    box-shadow: $button-stroke $color-primary;
    color: $color-primary;

    &:hover,
    &.usa-button-hover {
      box-shadow: $button-stroke $color-primary-darker;
      color: $color-primary-darker;
    }

    &:active,
    &.usa-button-active {
      box-shadow: $button-stroke $color-primary-darkest;
      color: $color-primary-darkest;
    }

    &:focus,
    &.usa-button-focus {
      box-shadow: $button-stroke $color-primary-darkest, $focus-shadow;
    }
  }

  &.usa-button-outline-inverse {
    background: transparent;
    box-shadow: $button-stroke $color-white;
    color: $color-white;

    &:hover,
    &.usa-button-hover {
      box-shadow: $button-stroke $color-gray-lighter;
      color: $color-gray-lighter;
    }

    &:active,
    &.usa-button-active {
      box-shadow: $button-stroke $color-gray-light;
      color: $color-gray-lighter;
    }

    &:focus,
    &.usa-button-focus {
      box-shadow: $button-stroke $color-gray-light, $focus-shadow;
    }
  }

  &.usa-button-big {
    font-size: 1.9rem;
    padding: 1.5rem 3rem;
  }
}
/* stylelint-disable */

.usa-button:disabled,
.usa-button-disabled {
  background-color: $color-gray-lighter;
  color: $color-gray-dark;
  pointer-events: none;

  &:hover,
  &.usa-button-hover,
  &:active,
  &.usa-button-active,
  &:focus {
    background-color: $color-gray-lighter;
    border: 0;
    box-shadow: none;
    color: $color-gray-dark;
  }
}

@mixin button-unstyled {
  background-color: transparent;
  border: 0;
  border-radius: 0;
  font-weight: $font-normal;
  margin: 0;
  outline: 0;
  padding: 0;
  text-align: left;
  -webkit-font-smoothing: auto;

  &:hover {
    background-color: transparent;
  }
}

.usa-button-unstyled {
  @include button-unstyled;
}
